import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import PageSEO from '../components/PageSEO';
import ArticleCard from '../components/ui/ArticleCard';
import Hero from '../components/ui/Hero';
import LayoutContainer from '../components/ui/LayoutContainer';
import { LocalizedSEO } from '../fragments';
import { ColorObject } from '../graphql-fragments/ColorObject';
import { RawPortableText } from '../types';
import { usePagination } from '../utils/hooks';
import { sortBy } from '../utils/nodash';
import { clsx, getEstimatedReadingTimeInSeconds, getReferenceUrl } from '../utils/utils';
import * as styles from './blog.module.scss';

export interface Article {
  title: string;
  slug: {
    current: string;
  };
  forceUrlEqualSlug?: boolean;
  category: {
    title: string;
  };
  image: {
    asset: {
      url: string;
    };
  };
  introText: string;
  _createdAt: string;
  modificationDate?: string;
  _rawBody: RawPortableText;
}

interface QueryData {
  allSanityArticle: {
    nodes: Array<Article>;
  };
  sanityBlogPage: {
    title: string;
    slug: {
      current: string;
    };
    hero: {
      topic: string;
      title: string;
      text: string;
      images: Array<{
        asset: {
          url: string;
        };
        alt?: string;
      }>;
      mobileImage?: {
        asset: {
          url: string;
        };
        alt?: string;
      };
      color?: ColorObject;
      withForm?: boolean;
    };

    seo?: LocalizedSEO;
  };
}

const BlogPage = (): React.ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    {
      allSanityArticle {
        nodes {
          _createdAt
          modificationDate
          title
          slug {
            current
          }
          forceUrlEqualSlug
          category {
            title
          }
          image {
            asset {
              url
            }
          }
          _rawBody
        }
      }
      sanityBlogPage {
        hero {
          topic
          title
          text
          images {
            asset {
              url
            }
          }
          mobileImage {
            asset {
              url
            }
          }
          color {
            title
            value
          }
        }
        seo {
          ...SEO
        }
      }
    }
  `);

  const {
    allSanityArticle: { nodes: articles },
    sanityBlogPage: { hero, seo },
  } = data;

  const sortedArticles = sortBy(
    articles,
    article => new Date(article.modificationDate || article._createdAt),
    'desc',
  );

  const { currentPageItems: currentPageArticles, renderPagination } = usePagination(
    sortedArticles,
    6,
  );

  return (
    <LayoutContainer>
      <PageSEO defaultTitle="Blog" pageSEO={seo} />
      <Hero
        heroType="genericPage"
        images={hero.images.map(image => image)}
        mobileImage={hero.mobileImage}
        color={hero.color?.title}
        topic={hero.topic}
        title={hero.title}
        text={hero.text}
      ></Hero>
      <section className={clsx(styles.section)}>
        <div className={styles.articlesContainer}>
          {currentPageArticles.map((article, i) => (
            <ArticleCard
              key={i}
              className={styles.articleCard}
              title={article.title}
              url={getReferenceUrl(
                'SanityArticle',
                article.slug.current,
                article.forceUrlEqualSlug,
              )}
              category={article.category}
              date={new Date(article.modificationDate || article._createdAt)}
              imageUrl={article.image.asset.url}
              readingTimeInMinutes={Math.round(
                getEstimatedReadingTimeInSeconds(article._rawBody) / 60,
              )}
            ></ArticleCard>
          ))}
        </div>
        {renderPagination()}
      </section>
    </LayoutContainer>
  );
};

export default BlogPage;
