// extracted by mini-css-extract-plugin
export var avatarContainer = "ArticleCard-module--avatarContainer--c9832";
export var avatarImage = "ArticleCard-module--avatarImage--88233";
export var card = "ArticleCard-module--card--4d935";
export var cardBody = "ArticleCard-module--cardBody--c684b";
export var cardCategory = "ArticleCard-module--cardCategory--2b727";
export var cardDetails = "ArticleCard-module--cardDetails--1cb1b";
export var cardImage = "ArticleCard-module--cardImage--2f539";
export var cardImageWrapper = "ArticleCard-module--cardImageWrapper--6e69c";
export var cardTitle = "ArticleCard-module--cardTitle--b7a27";
export var categoryAndTitle = "ArticleCard-module--categoryAndTitle--225ce";
export var date = "ArticleCard-module--date--ca180";
export var featuredCard = "ArticleCard-module--featuredCard--fb749";
export var readingTime = "ArticleCard-module--readingTime--bf0d3";