import { Link } from 'gatsby';
import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './ArticleCard.module.scss';

export interface ArticleCardProps {
  className?: string;
  featuredCard?: boolean;
  imageUrl: string;
  author?: {
    name: string;
    imageUrl?: string;
  };
  title: string;
  url: string;
  category: {
    title: string;
  };
  date: Date;
  readingTimeInMinutes: number;
}

const ArticleCard = ({
  className,
  featuredCard,
  imageUrl,
  author,
  title,
  url,
  category,
  date,
  readingTimeInMinutes,
}: ArticleCardProps): React.ReactElement => {
  const authorInitial = author ? Array.from(author.name)[0] : 'G';

  return (
    <Link to={url} className={clsx(styles.card, className, featuredCard && styles.featuredCard)}>
      <div className={styles.cardImageWrapper}>
        <div className={styles.cardImage}>
          <img src={imageUrl} alt="" className={styles.image} />
        </div>

        {author && (
          <div className={styles.avatarContainer}>
            {author.imageUrl ? (
              <img src={author.imageUrl} className={styles.avatarImage} alt="" />
            ) : (
              <span>{authorInitial}</span>
            )}
            {featuredCard && <span>{author.name}</span>}
          </div>
        )}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.categoryAndTitle}>
          {/* <span className={styles.cardCategory}>{category.title}</span> */}
          <p className={styles.cardTitle}>{title}</p>
        </div>
        <div className={styles.cardDetails}>
          <span className={styles.date}>
            {String(date.getDate()).padStart(2, '0') +
              '/' +
              String(date.getMonth() + 1).padStart(2, '0') +
              '/' +
              date.getFullYear()}{' '}
          </span>
          <span className={styles.readingTime}>| {readingTimeInMinutes} min read</span>
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;
